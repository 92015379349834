import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Spinner, SPINNER_WHITE } from './Spinner';

const OverlaySimple = ({ isShowing = true, className = '' }) => (
    <div
        className={classNames(
            isShowing ? '' : 'hidden',
            'absolute top-0 left-0 z-60 bg-gradient-radial from-black-200 to-black flex justify-center items-center h-full w-full',
            className
        )}
        data-testid="loading-overlay"
    >
        <Spinner width="w-12" height="h-12" borderColor={SPINNER_WHITE} />
    </div>
);

OverlaySimple.propTypes = {
    isShowing: PropTypes.bool,
    className: PropTypes.string,
};

export default OverlaySimple;
